.dashboard-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.dashboard-header {
  flex: 0 0 auto;
  min-height: 44px;
}

.dashboard-content {
  flex: 1 1 auto;
  overflow-y: auto;
}

.dashboard-welcome-section {
  height: calc(100vh - 44px);
  background-image: linear-gradient(#eee2de, #8f12b363);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 6rem;
}

.dashboard-card-header {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-image: linear-gradient(#eee2de, #6c12b3);
  padding: 3rem;
  position: relative;
}

.dashboard-card-header-name {
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  color: white;
}

.dashboard-card-footer {
  display: flex;
  align-items: flex-end;
  justify-content: end;
  margin-top: 1rem;
}

.dashboard-exlore-section {
  background-color: rgb(229, 224, 233);
  padding: 3rem;
  height: calc(100vh - 44px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.app-header {
  position:fixed;
  width:100%;
  z-index: 10;
  top:0px;
  background-color: #fff;

}
