/* Large */
.header {
  display: flex;
  flex-flow: row wrap;
  /* This aligns items to the end line on main-axis */
  justify-content: flex-end;
  margin-top: 1rem;
}

/* Medium screens */
@media all and (max-width: 800px) {
  .header {
    /* When on medium sized screens, we center it by evenly distributing empty space around items */
    justify-content: space-around;
    margin-top: 1rem;
  }
}

/* Small screens */
@media all and (max-width: 500px) {
  .header {
    /* On small screens, we are no longer using row direction but column */
    flex-direction: column;
    margin-top: 1rem;
  }
}

.card {
  width: 10rem;
  height: 8rem;
}
.search-card {
  padding-top: 1rem;
  width: 22rem;
  height: 8rem;
}
.cards-panel {
  padding: 20px;
  margin: 1rem 6rem;
}
.search-cards-panel {
  margin: 1rem 1rem;
}

.cards-list {
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: repeat(4, 1fr);
  margin-top: 15px;
}

.card-title {
  font-size: large;
  font-weight: bolder;
  height: 3.5rem;
}

.card-title-long {
  font-size: large;
  font-weight: bolder;
  height: 3.5rem;
  white-space: nowrap; /* Keep the text on a single line */
  overflow: hidden; /* Hide text that goes beyond the container */
  text-overflow: ellipsis; /* Add ellipsis at the end of the text */
  max-width: 250px; /* Set a max-width for the text */
  cursor: default; /* Change cursor to indicate the text is interactive */
}

.card-title-long:hover {
  padding: 1rem 1rem 1rem 1rem;
  font-size: large;
  font-weight: bolder;
  overflow: visible; /* Show the overflow on hover */
  white-space: normal; /* Allow the text to wrap */
  background-color: #f3f3f3; /* Optional: Add a background color on hover */
  z-index: 10; /* Make sure the text is above other elements */
  position: absolute; /* Take the element out of the flow so it can overlap */
}

.card-time {
  display: flex;
  font-size: small;
  font-weight: bolder;
  justify-content: flex-end;
}

.card-status {
  font-weight: bolder;
  font-size: small;
  display: flex;
  justify-content: flex-end;
  color: #5e00b5;
  padding-top: 0.5rem;
}

.card-download {
  display: flex;
  justify-content: flex-start;
}

.normal-text-style {
  font-size: medium;
  height: 2rem;
  padding-left: 0.5rem;
}
.long-text-style {
  height: 2rem;
  padding-left: 0.5rem;
  white-space: nowrap; /* Keep the text on a single line */
  overflow: hidden; /* Hide text that goes beyond the container */
  text-overflow: ellipsis; /* Add ellipsis at the end of the text */
  max-width: 300px; /* Set a max-width for the text */
  cursor: default; /* Change cursor to indicate the text is interactive */
}

.long-text-style:hover {
  padding: 1rem 1rem 1rem 1rem;
  overflow: visible; /* Show the overflow on hover */
  white-space: normal; /* Allow the text to wrap */
  background-color: #f3f3f3; /* Optional: Add a background color on hover */
  z-index: 10; /* Make sure the text is above other elements */
  position: absolute; /* Take the element out of the flow so it can overlap */
}

input {
  padding: 10px;
  width: 500px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

.search-header {
  padding: 20px;
  margin-left: 6rem;
}

.error-banner {
  padding-top: 5%;
  margin-left: 6rem;
}

.question-card {
  width: 320px;
}

.search-question-card {
  width: 420px;
}
.warning-banner {
  --wpp-banner-content-wrapper-max-width: 100% !important;
  --banner-padding: 8px 16px !important;
  --banner-min-height: 70px !important;
}

/* question compare component styles */
.main {
  margin: 2rem;
}

.wrapper {
  min-height: 100vh;
  margin-left: 30px;
  padding: 8px;
  background-color: var(--wpp-grey-color-100);
  box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%),
    0 1px 3px 0 rgb(0 0 0 / 12%);
}

.highlightText {
  color: #a020f0;
  background: lightgrey;
  padding: 0.0625rem 0.5rem;
  border-radius: 0.25rem;
  word-break: break-all;
}

.differenceCard {
  width: 100%;
  margin-bottom: 4px;
  margin-top: 6px;
}

/* question compare component styles */
