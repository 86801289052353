.row {
  display: flex;
  align-items: center;
  justify-content: center;
}

.row:not(:last-child) {
  margin-bottom: 16px;
}

.value {
  font-size: 78px;
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 2px;
  font-family: "Courier New", Courier, monospace;
}

.button {
  appearance: none;
  border: none;
  background: none;
  font-size: 32px;
  padding-left: 12px;
  padding-right: 12px;
  outline: none;
  border: 2px solid transparent;
  color: rgb(112, 76, 182);
  padding-bottom: 4px;
  cursor: pointer;
  background-color: rgba(112, 76, 182, 0.1);
  border-radius: 2px;
  transition: all 0.15s;
}

.textbox {
  font-size: 32px;
  padding: 2px;
  width: 64px;
  text-align: center;
  margin-right: 8px;
}

.button:hover,
.button:focus {
  border: 2px solid rgba(112, 76, 182, 0.4);
}

.button:active {
  background-color: rgba(112, 76, 182, 0.2);
}

.asyncButton {
  composes: button;
  position: relative;
  margin-left: 8px;
}

.asyncButton:after {
  content: "";
  background-color: rgba(112, 76, 182, 0.15);
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0;
  transition: width 1s linear, opacity 0.5s ease 1s;
}

.asyncButton:active:after {
  width: 0%;
  opacity: 1;
  transition: 0s;
}

.drag-drop-panel {
  display: flex;
  
}

.workspace-panel-wrapper {
  width: 65%;
  margin-left: 312px;
}

.workspace-panel {
  border: 2px dashed rgba(112, 76, 182, 0.4);
  border-radius: 4px;
  padding: 16px;
  margin-bottom: 16px;
  transition: all 0.15s;
  margin-top: 20px;
  width: 1000px;
}

.right-panel {
 
  border: 2px dashed rgba(112, 76, 182, 0.4);
  border-radius: 4px;
  padding: 16px;
  width: 1000px;
}

.question-card {
  
}

.workspace-header {
  display: flex;
  /*align-items: flex-end;*/
  justify-content: flex-end;
  margin-top: 6rem;
  margin-bottom: 3rem;
}

.add-bottom {
  position: absolute;
  padding-right: 10rem;
  bottom: 0;
  right: 0;
}

.workspace-search-button {
  padding-right: 1rem;
}

.workspace-preview-button {
  padding-right: 4rem;
}

.out-panel-components {
  margin-top: 4rem;
}

.builder-header {
  z-index: 10;
  position: fixed;
}



.out-panel-sidebar {
  background-color: transparent;
  z-index: 20;
  width: 300px;
  margin-top: -45px;
  position: fixed;
}

.out-line-tree-item {
  padding-left: 1.5rem;
  border: 1px dashed rgba(112, 76, 182, 0.4);
  margin-top: 0.5rem;
  background-color: white;
}

.outline-list {
  padding-bottom: 0.5rem;
}

.outline-sync-button {
  margin-top: 0.5rem;
  display: flex;
  justify-content: flex-end;
}

.survey-header-input-title {
  --text-input-border-color: #fff;
  --wpp-typography-s-body-font-size: 30px;
}

.survey-header-input-desc {
  --textarea-border-color: #fff;
}
