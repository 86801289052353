.main {
  margin: 2rem;
}

.wrapper {
  display: flex;
}

.stepper {
  width: 19rem;
  text-align: center;
}

.pages {
  width: 100%;
  height: 600px;
  overflow: hidden;
}

.innerPage {
  transition: all 0.6s ease-in-out 0s;
  transform: translateY(0px);
}

.page {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 600px;
  background-color: var(--wpp-grey-color-100);
  box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%),
  0 1px 3px 0 rgb(0 0 0 / 12%);
}

.buttons {
  display: flex;
  align-items: baseline;
  justify-content: end;
  margin-top: 16px;
}

.button {
  margin-left: 1rem;
}

.text {
  // width: 240px !important;
}

wpp-step-v2-3-0::part(step-bg) {
  width: 14rem;
}

.highlightText {
  background: lightgrey;
  padding: 0.0625rem 0.5rem;
  border-radius: 0.25rem;
  word-break: break-all;
}

.centerContent {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
}
