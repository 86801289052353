.stepper-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 400px;
  background-color: var(--wpp-grey-color-100);
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
  0 1px 3px 0 rgba(0, 0, 0, 0.12);
  overflow: auto;
}

.highlightText {
  background: lightgrey;
  padding: 0.0625rem 0.5rem;
  border-radius: 0.25rem;
  word-break: break-all;
}

.form-container {
  width: 100%;

  .row {
    width: 100%;
    margin: 15px 0;
    display: flex;
    align-items: center;

    .label {
      width: 40%;
      float: left;
    }

    .tooltip {
      padding-left: 0.5rem;
    }

    .field {
      width: 60%;

      input {
        margin: 0;
        width: 100%;
        height: 30px;
      }

      input[type="checkbox"] {
        height: 25px;
        width: 20px;
      }

      select {
        height: 30px;
        padding: 5px;
      }
    }
  }
}