.card {
  padding: 2rem 2rem 2rem 2rem;
}

.card-container {
  width: 100%;
  height: auto;
}

.radio-group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1rem;
}
.add-bottom {
  position: absolute;
  padding-right: 10rem;
  bottom: 0;
  right: 0;
}

.answer-text {
  padding-bottom: 1rem;
}

.question-row-container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.question-modal-button-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.question-modal::part(side-modal) {
  justify-content: flex-end;
  width: 25%;
}

.loading-screen {
  margin: 10rem 8rem;
}

.skeleton-screen {
  display: flex;
  flex-direction: column;
  gap: 25px;
  padding: 20px;
  margin: 1rem 6rem;
}

.skeleton-row-container {
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin: 1rem 6rem;
}

.skeleton-row {
  display: flex;
  /* Spacing between cards */
  gap: 2rem;
}

.skeleton-card {
  min-width: 320px;
  min-height: 232px;
}

.error-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh; 
  background-color: #f5e6f7; 
  color: #9613e8;
  padding: 5rem;
  text-align: center;
}

.error-screen h1 {
  margin-bottom: 2.5rem;
}

.error-screen p {
  font-size: 1.2em;
}
