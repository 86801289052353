.questionnaire-previewer-container{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 60%;
  height: 100%;
  border: 2px dashed rgba(112, 76, 182, 0.4);
  border-radius: 4px;
  margin-top: 6rem;
  margin-left: 6rem;
  padding: 4rem;
}

.questionnaire-previewer-header{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 6rem;
  width: 100%;
}
.questionnaire-form-previewer{
  margin-top:2rem;
}

.questionnaire-previewer-text{
  color: #5e00b5;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
}

.questionnaire-previewer-description{
  padding-left: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: #5a5c5a;
}
